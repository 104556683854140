// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import IStore from 'lib/redux/models'

// React
import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react'

// Libraries
import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerToastWarning } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMenu } from 'hooks'

// Components
import * as Styled from './styled'
import { IconButton } from 'heeds-ds'
import Profile from 'components/Profile'
import TabNavigator from 'components/TabNavigator'

export interface IAthleteOutletContext {
  id: string
  isEditing: boolean
  personal_id: string
  navigateToNextTab: (to?: string) => void
  navigateToPreviousTab: (to?: string) => void
  blockNavigation: (to?: string) => void
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const AthleteRegisterLayout: FC<Props> = ({ children }) => {
  const { info, loading, error } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { id = 'cadastro', personal_id = '' } = useParams()
  const { pathname, state = {} } = useLocation()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [blocked, setBlocked] = useState(false)

  const isEditing = id !== 'cadastro'
  const avoidStepsNavigation =
    !isEditing && pathname === generatePath(urls.athleteGeneral, { id })
  const steps = [
    {
      label: 'DADOS GERAIS',
      to: generatePath(urls.athleteGeneral, { id, personal_id }),
    },
    {
      label: 'FINANCEIRO',
      to: generatePath(urls.athleteFinancial, { id, personal_id }),
    },
    {
      label: 'HORÁRIOS',
      to: generatePath(urls.athleteSchedule, { id, personal_id }),
    },
    {
      label: 'ANAMNESE',
      to: generatePath(urls.athleteAnamneses, { id, personal_id }),
    },
    {
      label: 'COMENTÁRIOS',
      to: generatePath(urls.athleteComments, { id, personal_id }),
    },
  ]

  const navigateToNextTab = (to?: string) => {
    const index = steps.findIndex((step) => step.to === pathname)
    const nextStep = steps[index + 1]
    const nextPath = nextStep?.to

    if (nextPath) {
      navigate(to || nextPath, { state })
    }
  }

  const navigateToPreviousTab = (to?: string) => {
    const index = steps.findIndex((step) => step.to === pathname)
    const previousStep = steps[index - 1]
    const previousPath = previousStep?.to

    if (previousPath) {
      navigate(to || previousPath, { state })
    }
  }

  const blockNavigation = (to?: string) => {
    const avoidStepsNavigation = pathname === generatePath(to || '', { id })
    setBlocked(avoidStepsNavigation)
  }

  const navigateToProfile = () => {
    if (!isEditing) {
      navigate(-1)
      return
    }
    navigate(
      generatePath(urls.athleteProfile, {
        id: info?.id,
      }),
    )
  }

  useLayoutEffect(() => {
    setPagename('ALUNOS')

    return () => setPagename('DASHBOARD')
  }, [setPagename])

  useLayoutEffect(() => {
    if (id && info?.id !== Number(id) && id !== 'cadastro') {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id, info?.id])

  useEffect(() => {
    const invalidId = id !== 'cadastro' && isNaN(Number(id))
    // TODO: Implement notFound
    const notFound = id && loading === false && error === '404'

    if (invalidId || notFound) {
      dispatch(triggerToastWarning({ customTitle: 'ID do atleta inválido!' }))
      navigate(urls.athletes)
    }
  }, [dispatch, error, id, loading, navigate])

  return (
    <div className="flex h-screen w-full flex-col overflow-hidden md:pb-6 md:pl-10 md:pr-12 md:pt-10">
      <div className="flex items-center gap-6">
        <div className="hidden md:flex">
          <IconButton
            iconName="arrowBack"
            onClick={navigateToProfile}
            size="large"
            track={buttonClickTracking}
            trackName="back_to_athlete_profile"
          />
        </div>
        <Profile name={isEditing && !loading ? info?.name : ''} />
      </div>
      <div
        className={cn(
          'flex flex-1 flex-col overflow-hidden rounded-2',
          'md:mt-4 md:border md:border-border-input',
        )}
      >
        <Styled.TabsWrapper>
          <TabNavigator
            avoidNextNavigation={avoidStepsNavigation}
            blockNavigation={blocked}
            className="p-0 pt-6 lg:p-0 lg:pt-6"
            items={id !== 'cadastro' ? steps : [steps[0]]}
          />
        </Styled.TabsWrapper>
        <div className="flex flex-1 flex-col overflow-hidden border-t border-border-input bg-surface">
          {children || (
            <Outlet
              context={{
                isEditing,
                id,
                personal_id,
                navigateToNextTab,
                navigateToPreviousTab,
                blockNavigation,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AthleteRegisterLayout
