// React
import { FC } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { workoutRoutineSchema } from 'schemas'
import { yupResolver } from '@hookform/resolvers/yup'

// Lbraries
import { generatePath, useNavigate } from 'react-router-dom'

// Misc
import { urls } from 'routes/paths'
import { useModal } from 'hooks/useModal'

// Components
import { InputText } from 'heeds-ds'
import { Modal } from 'components'

type IWorkoutFormData = {
  name: string
}

const ModalNewWorkoutTemplate: FC = () => {
  const methods = useForm<IWorkoutFormData>({
    resolver: yupResolver(workoutRoutineSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { handleSubmit, reset } = methods
  const navigate = useNavigate()

  const { closeModal } = useModal()

  const onSubmit = (form: IWorkoutFormData) => {
    navigate(
      generatePath(urls.libraryWorkoutModelCreateEdit, {
        id: 'novo-treino',
      }),
      { state: { name: form.name } },
    )
    closeModal()
  }

  return (
    <Modal
      title="Nome do modelo"
      description="Você pode escolher o nome que esse treino aparecerá na biblioteca."
      maxWidth="53rem"
      onClose={reset}
      primaryButton={{
        name: 'Criar modelo',
        onClick: handleSubmit(onSubmit),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="px-8">
          <div className="flex flex-col gap-2">
            <InputText
              label="Nome do modelo"
              name="name"
              scale="small"
              placeholder="Digite treino"
              mandatory
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ModalNewWorkoutTemplate
