import { useEffect, useState } from 'react'

const useKeyboardVisible = () => {
  const [keyboardVisible, setKeyboardVisible] = useState<boolean | null>(false)
  const [keyboardFocused, setKeyboardFocused] = useState(false)
  const [initialViewportHeight, setInitialViewportHeight] = useState<
    number | null
  >(null)

  useEffect(() => {
    const handleFocus = () => {
      setKeyboardFocused(true)
    }

    const handleBlur = () => {
      setKeyboardFocused(false)
    }

    document.addEventListener('focusin', handleFocus)
    document.addEventListener('focusout', handleBlur)

    return () => {
      document.removeEventListener('focusin', handleFocus)
      document.removeEventListener('focusout', handleBlur)
    }
  }, [])

  useEffect(() => {
    if (!window.visualViewport) return

    setInitialViewportHeight(window.visualViewport.height)

    const handleResize = () => {
      if (initialViewportHeight === null) return

      const isKeyboardNowVisible =
        window.visualViewport &&
        window.visualViewport.height < initialViewportHeight - 100
      setKeyboardVisible(isKeyboardNowVisible)
    }

    window.visualViewport.addEventListener('resize', handleResize)

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize)
      }
    }
  }, [initialViewportHeight])

  return keyboardVisible && keyboardFocused
}

export default useKeyboardVisible
