// Models
import {
  IWorkoutRoutinesPayload,
  IWorkoutRoutinesResponse,
  IWorkoutCreateRoutinePayload,
  IWorkoutDeleteRoutinePayload,
  IWorkoutGetRoutinePayload,
  IWorkoutLatestWorkoutPayload,
  IWorkoutPartialUpdateRoutinePayload,
  IWorkoutUpdateRoutinePayload,
  IWorkoutRoutineTemplatesResponse,
  IGetWorkoutRoutineTemplatePayload,
  IPartialUpdateWorkoutRoutineTemplatePayload,
} from './@types'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// Misc
import { objectToQueryString } from 'lib/query-string'
import { base64ToBlob, getApiErrors } from 'utils/functions'
import api from 'lib/axios/api'
import axios from 'axios'

export const loadWorkoutRoutines = async (
  formData: IWorkoutRoutinesPayload,
): Promise<IWorkoutRoutinesResponse> => {
  try {
    const query = formData
    const { data }: { data: IWorkoutRoutinesResponse } = await api.get(
      `workouts/routine/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadWorkoutRoutineTemplates = async (
  formData: IWorkoutRoutinesPayload,
): Promise<IWorkoutRoutineTemplatesResponse> => {
  try {
    const query = formData
    const { data }: { data: IWorkoutRoutineTemplatesResponse } = await api.get(
      `workouts/routine/template/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createWorkoutRoutine = async (
  formData: IWorkoutCreateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  try {
    const { data }: { data: IWorkoutRoutine } = await api.post(
      `workouts/routine/`,
      formData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getWorkoutRoutine = async (
  formData: IWorkoutGetRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.get(
      `workouts/routine/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateWorkoutRoutine = async (
  formData: IWorkoutUpdateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk, ...restForm } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.put(
      `workouts/routine/${routine_pk}/`,
      restForm,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateWorkoutRoutine = async (
  formData: IWorkoutPartialUpdateRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk, ...restForm } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.patch(
      `workouts/routine/${routine_pk}/`,
      restForm,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteWorkoutRoutine = async (
  formData: IWorkoutDeleteRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.delete(
      `workouts/routine/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteWorkoutRoutineTemplate = async (
  formData: IWorkoutDeleteRoutinePayload,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.delete(
      `workouts/routine/template/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getLatestWorkoutRoutine = async (
  formData: IWorkoutLatestWorkoutPayload,
): Promise<IWorkoutRoutine> => {
  const { athlete_pk } = formData
  try {
    const { data }: { data: IWorkoutRoutine } = await api.get(
      `workouts/latest-workout/${athlete_pk}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getWorkoutRoutineTemplate = async (
  payload: Pick<IGetWorkoutRoutineTemplatePayload, 'routine_pk'>,
): Promise<IWorkoutRoutine> => {
  const { routine_pk } = payload
  try {
    const { data }: { data: IWorkoutRoutine } = await api.get(
      `/workouts/routine/template/${routine_pk}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateWorkoutRoutineTemplate = async (
  payload: Omit<IPartialUpdateWorkoutRoutineTemplatePayload, 'successCallback'>,
): Promise<IWorkoutRoutine> => {
  const { routine_pk, ...params } = payload
  try {
    const { data }: { data: IWorkoutRoutine } = await api.patch(
      `/workouts/routine/template/${routine_pk}/`,
      params,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getWorkoutRoutinePDF = async (
  routineId: string,
  modelId?: string,
): Promise<Blob> => {
  try {
    const { data } = await api.get(
      `/workouts/routine/${routineId}/export-pdf/`,
      {
        responseType: 'text',
        timeout: 20000,
        params: {
          model_pk: modelId,
        },
      },
    )

    return base64ToBlob(data)
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
