// Models
import { IAthleteInfo } from 'services/athleteInfo/@types'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAuthState } from 'storage/auth/models'
import { TypeGenders } from 'models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteInformationSchema } from 'schemas'
import { cn } from 'utils/helpers/classess'
import { getCepByViaCep } from 'services/external'
import {
  prepareAthleteGeneralToDisplay,
  prepareAthleteGeneralToPayload,
} from 'filters/athlete'
import {
  triggerCreateAthlete,
  triggerLoadAthlete,
  triggerUpdateAthlete,
} from 'storage/athleteInfo/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import {
  requestForgotPassword,
  resendInviteEmail,
  triggerGetUserInviteList,
} from 'storage/auth/duck'
import { urls } from 'routes/paths'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import {
  Body,
  Button,
  DatePicker,
  FormButton,
  Icon,
  InputText,
  Loading,
  Select,
} from 'heeds-ds'
import {
  ModalConfirmation,
  NewContentBox,
  ModalEmailConfiguration,
} from 'components'

// Constants
import { GENDER_OPTIONS } from 'utils/constants'

const DESCRIPTION_ADDITIONAL_INFORMATION =
  'Contato de emergência será alguém que pode ajudar o seu aluno em algum momento de crise durante uma aula.'
const DESCRIPTION_GENERAL_DATA =
  'Preencha os dados pessoais do seu aluno. Nome, e-mail e sexo são obrigatórios.'

export interface FormInputs {
  address: string
  birthdate: string
  cep: string
  city: string
  comment: string
  complement: string
  email: string
  emergency_contact_name: string
  emergency_contact_phone_number: string
  instagram: string
  gender: TypeGenders
  name: string
  phone_number: string
  state: string
}

const AthleteGeneral: FC = () => {
  const { info, loading } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { athleteOverview } = useSelector<IStore, IStore>((state) => state)
  const { invite_list } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { overview } = athleteOverview
  const { id = '', isEditing } = useOutletContext<IAthleteOutletContext>()
  const { openModal, isVisible, closeModal } = useModal()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const isActive = overview?.status === 'active'

  const [cepIsLoading, setCepIsLoading] = useState(false)

  const formMethods = useForm<FormInputs>({
    resolver: yupResolver(athleteInformationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    formState: { isValid },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = formMethods

  const watchingCep = watch('cep')
  const watchingEmail = watch('email')

  const onSubmit = (data: FormInputs) => {
    const payload: IAthleteInfo = {
      ...prepareAthleteGeneralToPayload(data),
      id: id ? Number(id) : 0,
    }
    if (isEditing) {
      dispatch(
        triggerUpdateAthlete({
          ...payload,
        }),
      )
    } else {
      dispatch(
        triggerCreateAthlete({
          ...payload,
          successCallback: () => openModal('success-create-user'),
        }),
      )
    }
  }

  const updateLinkedFields = useCallback(
    (address: string, city: string, state: string) => {
      setValue('address', address)
      setValue('city', city)
      setValue('state', state)
    },
    [setValue],
  )

  const navigateToAthletes = () => {
    closeModal()
    window.postMessage('NAVIGATE_TO_ATHLETES')
    navigate(urls.athletes)
  }

  const navigateToRoutines = () => {
    info && navigate(generatePath(urls.workoutRoutineCreate, { id: info.id }))
  }

  const getCep = useCallback(async () => {
    if (watchingCep) {
      setCepIsLoading(true)

      try {
        const { localidade, logradouro, uf } = await getCepByViaCep(
          watchingCep.replace('-', ''),
        )
        updateLinkedFields(logradouro ?? '', localidade ?? '', uf ?? '')
      } catch (error) {
        updateLinkedFields('', '', '')
        setError('cep', { message: 'CEP não encontrado!' })
      } finally {
        setCepIsLoading(false)
      }
    }
  }, [setError, updateLinkedFields, watchingCep])

  const handleRequestPassword = () => {
    if (info?.email) {
      dispatch(
        requestForgotPassword({
          email: info.email,
          toast_message: 'O seu aluno recebera um email para redefinir a senha',
        }),
      )
    }
  }

  const handleConfirmationEmail = () => {
    const email = info?.email ? info?.email : watchingEmail
    if (info?.id) {
      dispatch(
        resendInviteEmail({
          email: email ?? '',
          athlete_profile_id: info.id,
        }),
      )
    }
    if (isValid && watchingEmail !== info?.email) {
      handleSubmit(onSubmit)()
    }
  }

  useEffect(() => {
    if (watchingCep?.match(/\d{5}-\d{3}/)) getCep()
  }, [getCep, watchingCep])

  useLayoutEffect(() => {
    if (isEditing && id) {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id, isEditing])

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthleteOverview(parseInt(id)))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (info && info.id === Number(id)) {
      const formattedInfo = prepareAthleteGeneralToDisplay(info)

      reset(formattedInfo)
    }
  }, [id, info, reset])

  useEffect(() => {
    if (info?.id) {
      dispatch(
        triggerGetUserInviteList({
          athlete_profile_id: String(info.id),
          page_size: 20,
        }),
      )
    }
  }, [dispatch, info?.id])

  if (loading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-1 flex-col overflow-auto xl:pl-10"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-6 px-6 pt-6 xl:grid xl:grid-cols-[30%_70%]">
          <div>
            <p className="text-copy3 font-bold">Dados Gerais</p>
            <p className="mt-2 text-copy3 text-text-subdued">
              {DESCRIPTION_GENERAL_DATA}
            </p>
          </div>
          <div className="grid gap-3 xl:w-9/12">
            <InputText
              scale="small"
              label="Nome Completo"
              mandatory
              placeholder="Digite o nome completo do aluno"
              name="name"
            />
            <div className="flex w-full flex-col items-center justify-center gap-6 xl:flex-row">
              <div className="w-full xl:w-5/12">
                <DatePicker
                  displayError
                  label="Data de Nascimento"
                  name="birthdate"
                  placeholder="Selecione a data"
                  scale="small"
                  mandatory
                />
              </div>
              <div className="w-full xl:w-3/12">
                <Select
                  label="Sexo"
                  name="gender"
                  options={GENDER_OPTIONS}
                  scale="small"
                  placeholder="Selecione"
                  mandatory
                />
              </div>
              <div className="w-full xl:w-3/12">
                <InputText
                  scale="small"
                  label="Telefone"
                  mask="phone"
                  name="phone_number"
                  placeholder="(00) 9 0000-0000"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center xl:flex-row xl:gap-2">
              <div className="w-full xl:w-4/6">
                <InputText
                  scale="small"
                  label="E-mail"
                  mandatory
                  placeholder="Digite seu e-mail"
                  name="email"
                  type="email"
                  disabled={isEditing && isActive}
                />
              </div>
              <div className="flex w-full items-end justify-end xl:w-3/6">
                <Button
                  variation="borderless"
                  size="xsmall"
                  onClick={() => openModal('new-accesses-link')}
                >
                  Configurações do e-mail
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NewContentBox
          title={
            <div className="flex gap-2">
              <Icon color={theme.colors.icon.critical} iconName="emergency" />
              <Body color={theme.colors.text.default} type="copy2" weight={700}>
                Contato de Emergência
              </Body>
            </div>
          }
          description={isDesktop && DESCRIPTION_ADDITIONAL_INFORMATION}
          collapsible={!isDesktop}
          padding={!isDesktop ? '16px 24px 0' : ''}
        >
          {!isDesktop && (
            <Body
              color={theme.colors.text.disabled}
              margin="0 0 40px"
              type="copy3"
              weight={400}
            >
              {DESCRIPTION_ADDITIONAL_INFORMATION}
            </Body>
          )}
          <div className="grid gap-3 xl:w-9/12">
            <div className="flex flex-col xl:flex-row xl:gap-6">
              <InputText
                scale="small"
                label="Nome do contato"
                placeholder="Digite o nome completo"
                name="emergency_contact_name"
                className="w-full xl:w-5/6"
              />
              <InputText
                scale="small"
                label="Telefone"
                mask="phone"
                name="emergency_contact_phone_number"
                placeholder="(00) 9 0000-0000"
                className="w-full xl:w-2/6"
              />
            </div>
          </div>
        </NewContentBox>
        <NewContentBox
          title={
            <Body color={theme.colors.text.default} type="copy2" weight={700}>
              Informações adicionais
            </Body>
          }
          description="Dados opcionais"
          collapsible={!isDesktop}
          margin={!isDesktop ? '0 0 24px' : ''}
          padding={!isDesktop ? '40px 24px 0' : ''}
        >
          <div className="grid gap-3 xl:w-9/12">
            <div className="flex flex-col xl:flex-row xl:gap-6">
              <InputText
                scale="small"
                label="CEP"
                mask="cep"
                placeholder="Digite o CEP"
                name="cep"
                className="w-full xl:w-1/4"
              />
              <div className="flex flex-row gap-6">
                <InputText
                  scale="small"
                  disabled={cepIsLoading}
                  label="UF"
                  placeholder="Selecione"
                  name="state"
                  className="w-2/5"
                />
                <InputText
                  scale="small"
                  disabled={cepIsLoading}
                  label="Cidade"
                  placeholder="Digite a cidade do aluno"
                  name="city"
                  className="w-3/5"
                />
              </div>
            </div>

            <InputText
              scale="small"
              disabled={cepIsLoading}
              label="Endereço"
              placeholder="Digite o endereço do aluno"
              name="address"
            />

            <InputText
              scale="small"
              label="Complemento"
              placeholder="Digite o complemento do aluno"
              name="complement"
            />

            <InputText
              scale="small"
              label="Instagram"
              placeholder="Digite o instagram do aluno"
              name="instagram"
            />
          </div>
        </NewContentBox>
      </form>
      <div
        className={cn(
          'flex justify-center border-t border-border-input bg-surface px-10 py-4 xl:justify-end',
          {
            'justify-between xl:justify-between': !isEditing,
          },
        )}
      >
        {!isEditing && (
          <Button
            cancel
            size={isDesktop ? 'xsmall' : 'small'}
            onClick={() => {
              window.postMessage('CLOSE_WEBVIEW')
              navigate(-1)
            }}
          >
            Cancelar
          </Button>
        )}
        <FormButton
          size={isDesktop ? 'xsmall' : 'small'}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          loading={loading}
        >
          Salvar
        </FormButton>
      </div>

      {isVisible === 'success-create-user' && (
        <ModalConfirmation
          cancelTitle="Lista de alunos"
          confirmTitle="Ir para criação de treino"
          description="Você agora pode criar treinos para esse aluno. Deseja ir para criação de treinos?"
          infoCancel
          onCancel={navigateToAthletes}
          onConfirm={navigateToRoutines}
          title="Aluno cadastrado!"
          width="fit-content"
        />
      )}
      {isVisible === 'new-accesses-link' && (
        <ModalEmailConfiguration
          handleConfirmationEmail={handleConfirmationEmail}
          handleRequestPassword={handleRequestPassword}
          newEmail={
            !watchingEmail || (isValid && watchingEmail !== info?.invite_email)
          }
          inviteUrl={invite_list?.results[0]?.token}
          isActive={isActive}
          isEditing={isEditing}
          isValid={isValid}
          registerDate={info?.created_at}
        />
      )}
    </FormProvider>
  )
}

export default AthleteGeneral
