// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import {
  ILoadAnamneseAnswersPayload,
  ILoadAnamneseAnswersResponse,
  IUpdateAnamneseAnswerPayload,
  ICreateAnamneseAnswersPayload,
} from './@types'

// Misc
import { getApiErrors } from 'utils/functions'
import { objectToQueryString } from 'lib/query-string'
import api from 'lib/axios/api'
import axios from 'axios'

export const createAnamneseAnswers = async (
  payload: ICreateAnamneseAnswersPayload,
): Promise<IAnamneseAnswer> => {
  try {
    const { athlete_id, anamnesis_template_ids } = payload
    const { data }: { data: IAnamneseAnswer } = await api.post(
      `athletes/${athlete_id}/send-anamnesis/`,
      { anamnesis_template_ids },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getAnamneseAnswer = async (
  id: number,
): Promise<IAnamneseAnswer> => {
  try {
    const { data }: { data: IAnamneseAnswer } = await api.get(
      `/assessments/anamnesis-answer/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateAnamneseAnswer = async (
  payload: IAnamneseAnswer,
): Promise<IAnamneseAnswer> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IAnamneseAnswer } = await api.put(
      `/assessments/anamnesis-answer/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partilaUpdateAnamneseAnswer = async (
  payload: IUpdateAnamneseAnswerPayload,
): Promise<IAnamneseAnswer> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IAnamneseAnswer } = await api.patch(
      `/assessments/anamnesis-answer/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteAnamneseAnswer = async (
  id: string,
): Promise<IAnamneseAnswer> => {
  try {
    const { data }: { data: IAnamneseAnswer } = await api.delete(
      `/assessments/anamnesis-answer/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getAnamneseAnswers = async (
  payload: ILoadAnamneseAnswersPayload,
): Promise<ILoadAnamneseAnswersResponse> => {
  try {
    const { athlete_pk, ...params } = payload
    const { data }: { data: ILoadAnamneseAnswersResponse } = await api.get(
      `assessments/athletes/${athlete_pk}/anamnesis-answer/`,
      {
        params,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
