// Models
import {
  ILoginResponse,
  IRegisterPayload,
  IRegisterResponse,
  IResetPasswordPayload,
  IStatusResponse,
  ITokensResponse,
  INewPasswordPayload,
  INewPasswordResponse,
  TokenType,
  IUser,
  IUserInvite,
  IBackendLoginPayload,
  IUserInvitePayload,
  IEmailInvitePayload,
} from 'storage/auth/models'
import { IGetUserInvitePayload, IGetUserInviteResponse } from './@types'

// Libraries

// Misc
import api, { authApi } from 'lib/axios/api'
import { getApiErrors } from 'utils/functions'
import axios from 'axios'

export const login = async (
  requestData: IBackendLoginPayload,
): Promise<IUser & ITokensResponse> => {
  try {
    const {
      data: { access, refresh, data },
    }: { data: ILoginResponse } = await authApi.post(
      'users/auth/token/',
      requestData,
    )
    return {
      access,
      refresh,
      userId: data.pk,
      profileId: data.profile_pk,
      email: data.email,
      name: data.name,
      type: data.type,
      provider: data.provider,
      has_appointment_time: data.has_appointment_time,
      has_completed_profile_data: data.has_completed_profile_data,
      has_plans: data.has_plans,
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const register = async (
  formData: IRegisterPayload,
): Promise<IRegisterResponse> => {
  try {
    const { data }: { data: IRegisterResponse } = await authApi.post(
      'users/',
      formData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const resendEmailConfirmation = async (
  email: string,
): Promise<boolean> => {
  try {
    const response: boolean = await authApi.post('users/resend-verify-email/', {
      email,
    })

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const resendEmailInvite = async (
  payload: IEmailInvitePayload,
): Promise<IStatusResponse> => {
  try {
    const response: IStatusResponse = await api.post(
      'users/invite/resend/',
      payload,
    )

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const registerEmailConfirmation = async (
  token: string,
): Promise<boolean> => {
  try {
    const response: boolean = await authApi.post('users/verify-email/', {
      token,
    })
    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const requestForgotPasswordEmail = async (
  email: string,
): Promise<IStatusResponse> => {
  try {
    const response: IStatusResponse = await authApi.post(
      'users/password-reset/',
      {
        email,
      },
    )

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const resetPassword = async (
  payload: IResetPasswordPayload,
): Promise<IStatusResponse> => {
  try {
    const response = await authApi.post(
      'users/password-reset/confirm/',
      payload,
    )
    const data: IStatusResponse = response.data
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const newPassword = async (
  data: INewPasswordPayload,
): Promise<INewPasswordResponse> => {
  try {
    const response: INewPasswordResponse = await api.put(
      `users/new-password/`,
      { new_password: data.newPassword },
    )

    return response
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const validateResetToken = async (
  token: TokenType,
): Promise<IStatusResponse> => {
  try {
    const response = await authApi.post(
      'users/password-reset/validate_token/',
      {
        token,
      },
    )
    const data: IStatusResponse = response.data

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const userInvite = async (
  payload: IUserInvitePayload,
): Promise<IUserInvite> => {
  try {
    const response = await api.post('users/invite/', payload)
    const data: IUserInvite = response.data

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getUserInvite = async (
  payload: IGetUserInvitePayload,
): Promise<IGetUserInviteResponse> => {
  try {
    const { data }: { data: IGetUserInviteResponse } = await api.get(
      'users/invite/',
      {
        params: payload,
      },
    )
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const requestRefreshToken = async (
  refreshToken: string,
): Promise<string | ITokensResponse> => {
  // Important! Do NOT use the axios instance that you supplied to applyAuthTokenInterceptor (in our case 'axiosInstance')
  // because this will result in an infinite loop when trying to refresh the token.
  // Use the global axios client or a different instance
  try {
    const { data }: { data: ITokensResponse } = await authApi.post(
      `users/auth/token/refresh/`,
      { refresh: refreshToken },
    )

    return data.access
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
