// React
import { FC, ReactNode } from 'react'

// Misc
import useKeyboardVisible from 'hooks/useKeyboardVisible'

type Props = {
  children: ReactNode | ReactNode[]
}

const KeyboardSafeContainer: FC<Props> = ({ children }) => {
  const isKeyboardVisible = useKeyboardVisible()

  if (isKeyboardVisible) {
    return null
  }

  return <>{children}</>
}

export default KeyboardSafeContainer
