// Models
import { AthleteId } from 'models/api'
import { IAthleteSchedule, IAthleteSchedulesResponse } from './@types'
import {
  IAthleteSchedules,
  IPostSchedulesFormattedPayload,
  ISchedule,
} from 'storage/athleteSchedule/models'

// Misc
import {
  formatAthleteSchedules,
  formatAthleteSchedulesPayload,
  formatSchedulesResponse,
} from './formatters'
import { getApiErrors } from 'utils/functions'
import api from 'lib/axios/api'
import axios from 'axios'

/*
 * SERVICES:
 */
export const listAthleteSchedules = async (
  payload: AthleteId,
): Promise<IAthleteSchedules> => {
  try {
    const { data }: { data: IAthleteSchedulesResponse } = await api.get(
      `athletes/${payload}/schedules/`,
    )
    const formattedResponse = formatSchedulesResponse(data)

    return formattedResponse
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const listPersonalSchedules = async (): Promise<IAthleteSchedule[]> => {
  try {
    const response = await api.get(`personal/self/schedules/`)
    const results: IAthleteSchedule[] = response.data.results

    return results
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const postAthleteSchedules = async (
  payload: IPostSchedulesFormattedPayload,
): Promise<ISchedule[]> => {
  try {
    const { athlete, scheduleList } = formatAthleteSchedulesPayload(payload)
    const { data }: { data: IAthleteSchedule[] } = await api.post(
      `athletes/${athlete}/schedules/`,
      scheduleList,
    )
    const formattedResponse = formatAthleteSchedules(data)

    return formattedResponse
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
