// Models
import { CustomRouteProps } from './models'

// Libs
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Misc
import { isWebviewAccess } from 'utils/functions'
import { triggerClearWorkoutModels } from 'storage/workoutModel/duck'

export function AuthRoute({ element, isLoggedIn }: CustomRouteProps) {
  const dispatch = useDispatch()
  const isAWebView = isWebviewAccess()
  const redirect =
    new URLSearchParams(window.location.search).get('redirect') || ''

  if (isLoggedIn) {
    if (isAWebView) {
      dispatch(triggerClearWorkoutModels())
    }

    return <Navigate to={redirect || '/dashboard'} replace />
  }

  return element
}
