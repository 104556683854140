// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { useModal } from 'hooks'

// Components
import { Button, IconButton, InputText } from 'heeds-ds'
import { Modal } from 'components'

type ModalEmailConfigurationProps = {
  handleConfirmationEmail?: () => void
  handleRequestPassword?: () => void
  inviteUrl?: string
  isActive?: boolean
  isEditing?: boolean
  isValid?: boolean
  newEmail?: boolean
  registerDate?: string
}

const ModalEmailConfiguration: FC<ModalEmailConfigurationProps> = ({
  handleConfirmationEmail,
  handleRequestPassword,
  inviteUrl,
  isActive,
  isEditing,
  isValid,
  newEmail,
  registerDate,
}) => {
  const { closeModal } = useModal()

  const copyText = () => navigator.clipboard.writeText(inviteUrl || '')

  const onHandleConfirmationEmail = () => {
    handleConfirmationEmail?.()
    closeModal()
  }

  const onHandleRequestPassword = () => {
    handleRequestPassword?.()
    closeModal()
  }

  const convertedCreatedDate = () => {
    if (!registerDate) {
      return
    }

    const dates = registerDate?.split('T')

    const date = new Date(dates?.[0])
    const formattedTime = dates?.[1].slice(0, 5)
    const formattedDate = new Intl.DateTimeFormat('pt-BR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date)

    return `${formattedDate} às ${formattedTime}`
  }

  return (
    <Modal
      title="Configurações de acesso"
      description="Reenvie o link para um atleta modificar seu e-mail de acesso."
    >
      <div className="flex flex-col xl:w-[750px]">
        <div className="flex flex-col px-6 py-4 xl:flex-row">
          <div className="mb-4 xl:mb-0 xl:w-2/5">
            <p className="text-copy4 font-semibold">Informações de cadastro</p>
          </div>
          <div className="flex flex-col gap-2 xl:w-3/5">
            <div className="flex gap-1">
              <p className="text-copy4 font-semibold">Cadastrado:</p>
              <p className="text-copy4 text-text-subdued">
                {convertedCreatedDate()}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="text-copy4 font-semibold">E-mail confirmado:</p>
              <p className="text-copy4 text-text-subdued">
                {isActive ? 'Sim' : 'Não'}
              </p>
            </div>
          </div>
        </div>
        <div
          className={cn('flex flex-col px-6 py-4 xl:flex-row', {
            'xl:items-center': isActive,
          })}
        >
          <div className="mb-4 xl:mb-0 xl:w-2/5">
            <p className="text-copy4 font-semibold">E-mail de acesso</p>
          </div>
          <div className="flex flex-col gap-2 xl:w-3/5">
            <InputText
              placeholder="Digite seu e-mail"
              name="email"
              type="email"
              disabled={isEditing && isActive}
              displayError={false}
            />
            {!isActive && (
              <Button
                size="xsmall"
                variation="borderless"
                className="justify-start p-4"
                onClick={onHandleConfirmationEmail}
                disabled={newEmail && !isValid}
              >
                {newEmail ? 'Salvar e r' : 'R'}
                eenviar e-mail de confirmação
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col px-6 py-4 xl:flex-row">
          {isActive ? (
            <>
              <div className="mb-4 flex flex-col gap-1 xl:mb-0 xl:w-2/5">
                <p className="text-copy4 font-semibold">Redefinição de senha</p>
                <p className="text-copy4 text-text-subdued">
                  Um e-mail será enviado com as informações necessárias para que
                  o atleta realize o procedimento.
                </p>
              </div>
              <div className="flex flex-col p-0 xl:w-3/5">
                <Button
                  size="xsmall"
                  variation="borderless"
                  className="justify-start p-4"
                  onClick={onHandleRequestPassword}
                >
                  Enviar e-mail de redefinição de senha
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 flex flex-col gap-1 xl:mb-0 xl:w-2/5">
                <p className="text-copy4 font-semibold">Link de convite</p>
                <p className="text-copy4 text-text-subdued">
                  Envie o link para o atleta se cadastrar.
                </p>
              </div>
              <div className="flex justify-between xl:w-3/5">
                <div className="flex w-5/6 items-center justify-center rounded-1 border border-border-input px-4">
                  <p className="overflow-hidden truncate text-copy4 text-text-subdued">
                    {inviteUrl}
                  </p>
                </div>
                <IconButton onClick={copyText} iconName="contentCopy" />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalEmailConfiguration
