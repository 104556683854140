// Libraries
import { Overlay, Content, Close } from '@radix-ui/react-dialog'
import styled, { keyframes } from 'styled-components'

type Props = {
  $triggerAnimation?: boolean
  $maxWidth?: string
  visible?: boolean
  isSlide?: boolean
}

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`

const popIn = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const popOut = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideRightToLeft = keyframes`
 0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`
const slideLeftToRight = keyframes`
 0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }`

export const CloseButton = styled(Close)<Props>`
  position: absolute;
  ${({ isSlide }) => (isSlide ? 'left:-44px' : 'right:24px')};
  top: 20px;
  z-index: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`

export const GestureBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 0 10px 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px;
  }
`

export const GestureBar = styled.div`
  width: 32px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.icon.default};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`

export const DialogOverlay = styled(Overlay)`
  background-color: rgba(43, 42, 99, 0.54);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 8;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  }
`

export const DialogContent = styled(Content)<Props>`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 24px;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  z-index: 9;
  position: fixed;
  ${({ isSlide }) =>
    isSlide
      ? `
  top: 0;
  right: 0;
  height: 100vh;
  border-radius: 24px 0 0 24px;
  `
      : `
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `};
  animation-duration: 0.2s;
  animation-fill-mode: ease-in-out;
  max-height: 80vh;
  overflow-y: auto;

  animation-name: ${({ $triggerAnimation, isSlide }) =>
    isSlide
      ? $triggerAnimation
        ? slideRightToLeft
        : slideLeftToRight
      : $triggerAnimation
      ? popOut
      : popIn};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-radius: 24px 24px 0 0;
    bottom: 0;
    height: ${({ isSlide }) => (isSlide ? '82%' : 'auto')};
    top: auto;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in;
    width: 100vw;
    transform: translate(0%, 0%);
    animation-duration: 0.2s;
    animation-fill-mode: ease-in-out;
    animation-name: ${({ $triggerAnimation }) =>
      $triggerAnimation ? slideUp : slideDown};
  }
`
