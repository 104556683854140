// Models
import IStore from 'lib/redux/models'

// React
import {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cleanup, triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerListWorkoutsModel } from 'storage/workoutModel/duck'
import { triggerLoadWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Loading } from 'heeds-ds'
import {
  ModalRoutineInfoMobile,
  WorkoutAthleteInfo,
  WorkoutTabs,
} from 'components'

export type WorkoutLayoutContext = {
  blocked: boolean
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>
  steps: Array<{ label: string; to: string }>
}

type Props = {
  children?: ReactNode | ReactNode[]
}

const WorkoutLayout: FC<Props> = ({ children }) => {
  const { id = '', routine_id = '' } = useParams()
  const {
    info,
    error: errorAthleteInfo,
    loading: loadingAthleteInfo,
  } = useSelector((state: IStore) => state.athleteInfo)
  const { workoutRoutine } = useSelector(
    (state: IStore) => state.workoutRoutine,
  )
  const { workoutModels } = useSelector((state: IStore) => state.workoutModel)
  const { breakpoints } = useContext(ThemeContext)
  const { isVisible, openModal } = useModal()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [blocked, setBlocked] = useState(true)

  const pageMobile = searchParams.get('etapa')

  const steps = useMemo(
    () => [
      {
        label: 'Rotina',
        to: generatePath(
          routine_id ? urls.workoutRoutineEdit : urls.workoutRoutineCreate,
          { id, routine_id },
        ),
      },
      {
        label: 'Treinos',
        to: generatePath(urls.workoutRoutineModels, { id, routine_id }),
      },
      {
        label: 'Revisão',
        to: generatePath(urls.workoutRoutineReview, { id, routine_id }),
      },
    ],
    [id, routine_id],
  )

  const isStepTwo = useMemo(() => pathname === steps[1].to, [steps, pathname])

  useEffect(() => {
    if (!loadingAthleteInfo && errorAthleteInfo === '404') {
      dispatch(cleanup())
      navigate(-1)
    }
  }, [dispatch, errorAthleteInfo, loadingAthleteInfo, navigate])

  useLayoutEffect(() => {
    if (id) {
      const athleteId = Number(id)

      if (!info || info?.id !== athleteId) {
        dispatch(triggerLoadAthlete({ id: athleteId }))
      }

      if (routine_id) {
        const routine_pk = Number(routine_id)

        dispatch(triggerLoadWorkoutRoutine({ routine_pk }))
        dispatch(triggerListWorkoutsModel({ routine_pk }))
      }
    }
  }, [dispatch, id, info, routine_id])

  if (loadingAthleteInfo || (isStepTwo && !workoutModels)) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      {(isStepTwo && isDesktop) ||
        ((!pageMobile || pageMobile !== '2') && (
          <Styled.Header>
            <WorkoutAthleteInfo
              showInfo={pathname === steps[1].to}
              openModal={() => openModal('routine_info_modal')}
            />

            <WorkoutTabs blocked={blocked} steps={steps} />
          </Styled.Header>
        ))}

      <Styled.Content>
        {children || (
          <Outlet
            context={{
              blocked,
              setBlocked,
              steps,
            }}
          />
        )}
      </Styled.Content>

      {isVisible === 'routine_info_modal' && (
        <ModalRoutineInfoMobile workoutRoutine={workoutRoutine} />
      )}
    </Styled.Container>
  )
}

export default WorkoutLayout
