// Models
import { IPhysicalAssessment } from 'storage/assessmentPhysicalAssessment/models'
import {
  TCreatePhysicalAssessmentPayload,
  ILoadPhysicalAssessmentsPayload,
  ILoadPhysicalAssessmentsResponse,
  IUpdatePhysicalAssessmentPayload,
} from './@types'

// Misc
import { getApiErrors } from 'utils/functions'
import axios from 'axios'
import api from 'lib/axios/api'

export const createPhysicalAssessment = async (
  payload: TCreatePhysicalAssessmentPayload,
): Promise<IPhysicalAssessment> => {
  try {
    const { athlete } = payload
    const { data }: { data: IPhysicalAssessment } = await api.post(
      `assessments/athletes/${athlete}/physical-assessment/`,
      payload,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getPhysicalAssessment = async (
  id: number,
): Promise<IPhysicalAssessment> => {
  try {
    const { data }: { data: IPhysicalAssessment } = await api.get(
      `/assessments/physical-assessment/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updatePhysicalAssessment = async (
  payload: IPhysicalAssessment,
): Promise<IPhysicalAssessment> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IPhysicalAssessment } = await api.put(
      `/assessments/physical-assessment/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partilaUpdatePhysicalAssessment = async (
  payload: IUpdatePhysicalAssessmentPayload,
): Promise<IPhysicalAssessment> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IPhysicalAssessment } = await api.patch(
      `/assessments/physical-assessment/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deletePhysicalAssessment = async (
  id: number,
): Promise<IPhysicalAssessment> => {
  try {
    const { data }: { data: IPhysicalAssessment } = await api.delete(
      `/assessments/physical-assessment/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getPhysicalAssessments = async (
  payload: ILoadPhysicalAssessmentsPayload,
): Promise<ILoadPhysicalAssessmentsResponse> => {
  try {
    const { athlete_pk, ...params } = payload
    const { data }: { data: ILoadPhysicalAssessmentsResponse } = await api.get(
      `assessments/athletes/${athlete_pk}/physical-assessment/`,
      { params },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
