// React
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from '../../../utils/classes'

// Components
import { Icon } from '../..'

const KEYS_TO_BLUR = ['Enter', 'Go', 'Search', 'Done']

export type IFilter = {
  label: string
  param: string
  value: string
}

type Props = {
  className?: string
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  placeholder?: string
  scale?: 'small' | 'medium' | 'large'
  value: string
  autoFocus?: boolean
}

const InputSearch = ({
  className,
  disabled = false,
  onChange,
  onClick,
  placeholder = 'Pesquise aqui...',
  scale = 'medium',
  value,
  autoFocus = true,
}: Props) => {
  const theme = useContext(ThemeContext)
  const inputRef = useRef<HTMLInputElement>(null)

  const [focused, setFocused] = useState(false)

  const iconColor = useMemo(
    () => theme.colors.icon[disabled ? 'disabled' : 'default'],
    [disabled, theme.colors.icon],
  )

  useEffect(() => {
    if (!autoFocus && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.blur()
      }, 0)
    }
  }, [autoFocus])

  return (
    <div
      className={cn(
        'flex w-full items-center justify-between gap-4 rounded-1 border border-icon bg-surface',
        'px-4 py-3  focus:outline focus:outline-2 focus:outline-icon',
        {
          'bg-surface-disabled border-icon-disabled': disabled,
          'shadow-sm shadow-text-secondary outline outline-1 outline-icon':
            focused,
          'py-2': scale === 'small',
          'py-4': scale === 'large',
        },
        className,
      )}
      data-testid="component"
    >
      <Icon color={iconColor} iconName="search" size={24} />

      <input
        ref={inputRef}
        data-testid="input"
        inputMode="search"
        onBlur={() => setFocused(false)}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => {
          if (KEYS_TO_BLUR.includes(e.key)) {
            e.currentTarget.blur()
          }
        }}
        className={cn(
          'w-full text-copy3 outline-none placeholder:text-text-subdued disabled:bg-none',
          'disabled:text-text-disabled disabled:placeholder:text-text-disabled',
          { 'text-copy4': scale === 'small', 'text-copy2': scale === 'large' },
        )}
      />

      {onClick && (
        <button
          data-testid="show-filters-button"
          onClick={onClick}
          className="ml-px flex cursor-pointer items-center justify-center border-none bg-none p-0 disabled:cursor-auto"
        >
          <Icon color={iconColor} iconName="filter" size={24} />
        </button>
      )}
    </div>
  )
}

export default InputSearch
