// Models
import { TMenuDropdownOption } from '../../models'

// React
import { ReactNode, useEffect, useRef, useState } from 'react'

// Libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'

// Misc
import { cn } from '../../utils/classes'

// Components
import { Icon, LoadingSpinner } from '../'
import { colorStyles } from './styles'

type Props = {
  children: ReactNode | ReactNode[]
  disabled?: boolean
  items: TMenuDropdownOption[]
  onOpenChange?: (isOpen: boolean) => void
}

const DropdownMenu = ({ children, disabled, items, onOpenChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (!isOpen) return

      if (
        dropdownRef.current &&
        dropdownRef.current.contains(event.target as Node)
      ) {
        return
      }
      setIsOpen(false)
    }

    document.addEventListener('scroll', handleScroll, true)
    return () => document.removeEventListener('scroll', handleScroll, true)
  }, [isOpen])

  return (
    <Dropdown.Root
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
        if (onOpenChange) onOpenChange(open)
      }}
    >
      <Dropdown.Trigger
        asChild
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      >
        {children}
      </Dropdown.Trigger>

      <Dropdown.Portal>
        <Dropdown.Content
          ref={dropdownRef}
          align="end"
          sideOffset={4}
          className={cn(
            'animation-slide-down-and-fade z-10 min-w-44 rounded-1 bg-surface p-0',
            'shadow-[0px_2px_10px_rgba(0,0,0,0.1)]',
          )}
        >
          {items.map(
            ({
              label,
              onClick,
              active,
              color = 'default',
              disabled,
              icon,
              loading,
            }) => {
              return (
                <Dropdown.Item
                  disabled={disabled}
                  key={label}
                  onClick={!disabled ? onClick : undefined}
                  className={cn(
                    'flex min-h-10 w-full cursor-pointer select-none items-center gap-[10px] px-4 py-3 outline-none',
                    'text-copy4 text-text transition-all duration-100 ease-in-out hover:bg-badge-purpleBackground',
                    'hover:text-text-secondary',
                    {
                      'bg-action-highlightSofblue text-text-secondary font-semibold':
                        active,
                      'bg-surface-disabled opacity-50': disabled,
                      'justify-center': loading,
                    },
                    colorStyles({ color }),
                  )}
                >
                  {loading ? (
                    <LoadingSpinner size="xsmall" />
                  ) : (
                    <>
                      {icon && (
                        <Icon
                          iconName={icon}
                          className={cn(colorStyles({ color }))}
                        />
                      )}
                      {label}
                    </>
                  )}
                </Dropdown.Item>
              )
            },
          )}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}

export default DropdownMenu
