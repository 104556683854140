// Models
import { IExerciseData } from 'storage/exercise/models'
import { EPlanPeriodicityToPortuguese, IFormInputs, IModelInput } from 'models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import IStore from 'lib/redux/models'

// React
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cleanup, triggerLoadWorkoutRoutine } from 'storage/workoutRoutine/duck'
import {
  prepareModelsToDisplay,
  prepareModelsToPayload,
} from 'filters/workouts'
import {
  success,
  triggerCreateWorkoutModelTemplate,
  triggerDeleteWorkoutsModel,
  triggerListWorkoutsModel,
} from 'storage/workoutModel/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'
import { getModelName, uid } from 'utils/functions'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu, useModal } from 'hooks'

// Components
import {
  Button,
  Icon,
  IconButton,
  InputText,
  Loading,
  LoadingSpinner,
  StylizedIcons,
} from 'heeds-ds'
import {
  Modal,
  ModalDelete,
  ModalExerciseImage,
  ModalRoutineInfoMobile,
  RoutineInfo,
  WorkoutModelReviewCard,
} from 'components'
import { cn } from 'utils/helpers/classess'
import { getWorkoutRoutinePDF } from 'services/workoutRoutine'

const WorkoutRoutine: FC = () => {
  const { id = '', routine_id = '' } = useParams()
  const { loading: loadingAthleteInfo, overview } = useSelector(
    (state: IStore) => state.athleteOverview,
  )
  const { loading: loadingModels, models } = useSelector(
    (state: IStore) => state.workoutModel,
  )
  const {
    error,
    loading: loadingRoutine,
    workoutRoutine,
  } = useSelector((state: IStore) => state.workoutRoutine)
  const { breakpoints, colors } = useContext(ThemeContext)
  const { openModal, closeModal, isVisible } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()
  const [selectedModel, setSelectedModel] = useState(0)
  const [stateModels, setStateModels] = useState<IModelInput[]>()
  const [activePDF, setActivePDF] = useState<string | null>(null)

  const methods = useForm<IFormInputs>({
    defaultValues: { models: stateModels },
  })

  const modalMethods = useForm({
    defaultValues: { name: '' },
  })

  const goBack = () => {
    navigate(generatePath(urls.athleteRoutines, { id }), { replace: true })
  }

  const handleEditModel = useCallback(
    (modelIndex: number) => {
      dispatch(success({ workoutModels: stateModels }))
      navigate({
        pathname: generatePath(urls.workoutRoutineModels, { id, routine_id }),
        search: `treino-selecionado=${modelIndex}`,
      })
    },
    [dispatch, id, navigate, routine_id, stateModels],
  )

  const downloadWorkoutRoutinePDF = useCallback(
    async (routineId: string, modelId?: string) => {
      setActivePDF(modelId ? 'model' : 'routine')
      try {
        const blob = await getWorkoutRoutinePDF(routineId)
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
        URL.revokeObjectURL(url)
      } catch (error) {
        console.error('Error downloading PDF:', error)
        dispatch(
          triggerToastError({
            message: 'Error downloading PDF. Please try again.',
          }),
        )
      }
      setActivePDF(null)
    },
    [dispatch],
  )

  const handleAddToLibrary = useCallback(
    (modelIndex: number) => {
      setSelectedModel(modelIndex)
      openModal('add-workout-model-to-library')
    },
    [openModal],
  )

  const addWorkoutModelToLibrary = ({ name }: { name: string }) => {
    if (models) {
      const { workout_set, ...rest } = models.results[selectedModel]

      const updatedModels: IModelInput[] = [
        {
          ...rest,
          name,
          workout_set:
            workout_set?.reduce(
              (acc, set) => ({
                ...acc,
                [set.id]: set,
              }),
              {},
            ) ?? {},
        },
      ]

      const updatedData = prepareModelsToPayload(updatedModels)[0]

      const successCallback = () => {
        dispatch(
          triggerToastSuccess({
            customTitle: 'Seu treino foi salvo na biblioteca!',
            message:
              'Para visualizar e editar seu treino, acesse a biblioteca pelo menu',
          }),
        )
      }

      dispatch(
        triggerCreateWorkoutModelTemplate({
          ...updatedData,
          successCallback,
        }),
      )
    }

    closeModal()
  }

  const onRemoveModel = useCallback(
    (model_pk: string) => {
      if (stateModels) {
        const modelIndex = stateModels.findIndex(
          (model) => model.id === model_pk,
        )
        setSelectedModel(modelIndex)
        openModal('delete-exercise-modal')
      }
    },
    [openModal, stateModels],
  )

  const deleteModel = () => {
    const model = stateModels?.find((_, index) => index === selectedModel)
    model && dispatch(triggerDeleteWorkoutsModel({ model_pk: model.id }))
    closeModal()
    setSelectedModel(0)
  }

  const openExerciseGifModal = useCallback(
    (exercise: IExerciseData) => {
      setSelectedExercise(exercise)
      openModal('mobile-exercise-gif-modal')
    },
    [openModal],
  )

  const renderWorkoutModels = useMemo(() => {
    const workoutModels = prepareModelsToDisplay(models?.results ?? [])
    return workoutModels?.map((model, modelIndex) => {
      const options: Array<TMenuDropdownOption> = [
        {
          icon: 'pictureAsPdf',
          label: 'PDF do treino',
          onClick: () => downloadWorkoutRoutinePDF(routine_id, model.id),
          active: activePDF === 'model' ? true : false,
          loading: activePDF === 'model' ? true : false,
        },
        {
          icon: 'edit',
          label: 'Editar',
          onClick: () => handleEditModel(modelIndex),
        },
        {
          icon: 'book',
          label: 'Adicionar a biblioteca',
          onClick: () => handleAddToLibrary(modelIndex),
        },
        {
          icon: 'delete',
          label: 'Excluir',
          onClick: () => onRemoveModel(model.id),
          color: 'critical',
        },
      ]

      return (
        <WorkoutModelReviewCard
          key={model.id}
          openGifModal={openExerciseGifModal}
          options={options}
          shadow
          workoutModel={model}
        />
      )
    })
  }, [
    models?.results,
    routine_id,
    openExerciseGifModal,
    downloadWorkoutRoutinePDF,
    handleEditModel,
    handleAddToLibrary,
    onRemoveModel,
    activePDF,
  ])

  const handleAddModel = useCallback(() => {
    if (id && routine_id) {
      const modelsLength = stateModels?.length ?? 0
      const models: IModelInput[] = [
        ...(stateModels ?? []),
        {
          id: 'NEW' + uid(),
          name: getModelName(modelsLength),
          workout_set: {},
          workout_routine: Number(routine_id),
        },
      ]

      dispatch(success({ workoutModels: models }))
      navigate({
        pathname: generatePath(urls.workoutRoutineModels, { id, routine_id }),
        search: `?treino-selecionado=${modelsLength}`,
      })
    }
  }, [dispatch, id, navigate, routine_id, stateModels])

  const renderPDFAndEditButtons = useMemo(
    () => (
      <React.Fragment>
        <Button
          onClick={() => downloadWorkoutRoutinePDF(routine_id)}
          size="xsmall"
          track={buttonClickTracking}
          trackName="download_routine_pdf"
          variation="outlined"
          className="min-w-[200px]"
        >
          {activePDF === 'routine' ? (
            <LoadingSpinner size="xsmall" color="border-line" />
          ) : (
            <>
              <Icon iconName="pictureAsPdf" /> PDF da rotina
            </>
          )}
        </Button>

        <Button
          onClick={() =>
            navigate(
              generatePath(urls.workoutRoutineModels, { id, routine_id }),
            )
          }
          size="xsmall"
          track={buttonClickTracking}
          trackName="edit_routine"
        >
          <Icon iconName="edit" color={colors.text.onPrimary} /> Editar
        </Button>
      </React.Fragment>
    ),
    [
      colors.text.onPrimary,
      downloadWorkoutRoutinePDF,
      id,
      navigate,
      routine_id,
      activePDF,
    ],
  )

  useEffect(() => {
    if (models?.results) setStateModels(prepareModelsToDisplay(models.results))
  }, [models?.results])

  useLayoutEffect(() => {
    setPagename('TREINOS')
    return () => setPagename('DASHBOARD')
  }, [setPagename])

  useLayoutEffect(() => {
    if (id && routine_id) {
      const athleteId = parseInt(id)
      const routine_pk = parseInt(routine_id)

      dispatch(triggerLoadAthleteOverview(athleteId))
      dispatch(triggerLoadWorkoutRoutine({ routine_pk }))
      dispatch(triggerListWorkoutsModel({ routine_pk }))
    }
  }, [dispatch, id, routine_id])

  if (error) {
    navigate(-1)
    dispatch(cleanup())
  }

  if (isNaN(parseInt(id ?? '')) || isNaN(parseInt(routine_id ?? ''))) {
    return <Navigate to={urls.athletes} replace />
  }

  if (loadingAthleteInfo || loadingModels || loadingRoutine) {
    return <Loading active />
  }

  return (
    <div className="flex flex-1 flex-col p-6 xl:p-10 xl:pb-6 xl:pr-12">
      <FormProvider {...methods}>
        <div className="relative flex w-full flex-1 flex-col gap-4 ">
          <div className="flex w-full items-center justify-between rounded-2 border bg-surface p-4 shadow-sm">
            <Button
              onClick={goBack}
              variation="borderless"
              className="hidden xl:flex"
              size="xsmall"
            >
              <Icon iconName="arrowBack" color={colors.interactive.default} />
              Voltar para rotinas
            </Button>

            <div
              className={cn('flex w-full flex-col items-center gap-1', {
                'flex-row gap-4': !isDesktop,
              })}
            >
              {isDesktop ? (
                <>
                  <p className="text-center text-copy3 font-semibold">
                    {overview?.name}
                  </p>

                  <p className="text-center text-copy3 text-text-subdued">
                    {overview?.plan_periodicity &&
                      EPlanPeriodicityToPortuguese[overview?.plan_periodicity]}
                  </p>
                </>
              ) : (
                <>
                  <StylizedIcons iconName="genericWorkout" />
                  <p className="text-copy3 font-semibold text-text-secondary">
                    {workoutRoutine?.name}
                  </p>
                </>
              )}
            </div>

            <Button
              onClick={handleAddModel}
              size="xsmall"
              track={buttonClickTracking}
              trackName="add_new_workout_model"
              className="hidden xl:flex"
            >
              <Icon iconName="add" color={colors.text.onPrimary} />
              Adicionar treino
            </Button>

            <IconButton
              className="xl:hidden"
              iconName="info"
              onClick={() => openModal('workout-routine-info')}
              track={buttonClickTracking}
              trackName="open_workout_routine_info_modal"
            />
          </div>

          <div className="flex max-h-[calc(100vh-154px)] flex-1 gap-4 rounded-2 xl:border xl:bg-surface xl:shadow-sm">
            <div className="hidden flex-col xl:flex">
              <RoutineInfo workoutRoutine={workoutRoutine} />

              <div className="flex w-full justify-between px-6">
                {renderPDFAndEditButtons}
              </div>
            </div>

            <div className="flex flex-1 flex-col gap-4 py-0 xl:overflow-y-scroll xl:p-6">
              {renderWorkoutModels}
              <div className="min-h-[80px]"></div>
            </div>
          </div>

          <div className="fixed bottom-0 left-0 flex w-full justify-between border-t bg-surface px-10 py-6 xl:hidden">
            {renderPDFAndEditButtons}
          </div>
        </div>
      </FormProvider>

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeModal}
        />
      )}

      {isVisible === 'delete-exercise-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Tem certeza que deseja excluir este treino?"
          onConfirm={deleteModel}
          title="Excluir Treino"
        />
      )}

      {isVisible === 'workout-routine-info' && (
        <ModalRoutineInfoMobile workoutRoutine={workoutRoutine} />
      )}

      {isVisible === 'add-workout-model-to-library' && (
        <Modal
          title="Nome do modelo"
          description="Você pode escolher o nome que esse treino aparecerá na biblioteca."
          primaryButton={{
            name: 'Salvar modelo',
            onClick: modalMethods.handleSubmit(addWorkoutModelToLibrary),
          }}
          secondaryButton={{
            name: 'Cancelar',
          }}
          maxWidth="556px"
        >
          <FormProvider {...modalMethods}>
            <div className="flex px-6 py-4">
              <InputText
                displayError={false}
                label="Nome do treino"
                name="name"
                placeholder="Digite aqui o nome"
              />
            </div>
          </FormProvider>
        </Modal>
      )}
    </div>
  )
}

export default WorkoutRoutine
