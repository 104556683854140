//libraries
import { createGlobalStyle } from 'styled-components'

export const theme = {
  colors: {
    background: {
      default: '#FAFAFA',
      hovered: '#F1F2F3',
      pressed: '#EDEEEF',
      selected: '#EDEEEF',
    },
    badge: {
      redText: '#C04830',
      redBackground: '#FFF1F1',
      greenText: '#2C7D6A',
      greenBackground: '#EAFEF7',
      yellowText: '#916A21',
      yellowBackground: '#FFF7E8',
      greyText: '#575959',
      greyBackground: '#D2D5D8',
      pinkText: '#8E3374',
      pinkBackground: '#FBDDF3',
      purpleText: '#2B2A63',
      purpleBackground: '#E3E3F9',
      tealText: '#0F5156',
      tealBackground: '#D3F5F7',
    },
    surface: {
      default: '#FFFFFF',
      subdued: '#FAFBFB',
      hovered: '#F6F6F7',
      pressed: '#F1F2F3',
      depressed: '#EDEEEF',
      disabled: '#FAFBFB',
      selectedDefault: '#FAF9FF',
      selectedHovered: '#E6E6FD',
      selectedPressed: '#F1F0FF',
      warningDefault: '#F6D28D',
      warningSubdued: '#FFF5EA',
      warningSubduedHovered: '#FFF2E2',
      warningSubduedPressed: '#FFEBD3',
      criticalDefault: '#FED3D1',
      criticalSubdued: '#FFF4F4',
      criticalSubduedHovered: '#FFF0F0',
      criticalSubduedPressed: '#FFE9E8',
      successDefault: '#AEE9D1',
      successSubdued: '#F1F8F5',
      successSubduedHovered: '#ECF6F1',
      successSubduedPressed: '#E2F1EA',
      highlightDefault: '#CAD1F7',
      highlightSubdued: '#EFF1FF',
      highlightSubduedHovered: '#F5F7FF',
      highlightSubduedPressed: '#F9F9FF',
      neutralDefault: '#E4E5E7',
      neutralSubdued: '#F6F6F7',
      neutralHovered: '#DBDDDF',
      neutralPressed: '#C9CCD0',
      neutralDisabled: '#F1F2F3',
      primarySelected: '#FFDDD4',
      primaryHovered: '#FFE9E3',
      primarySelectedPressed: '#FFEEEA',
    },
    tag: {
      critical: '#A21F16',
      success: '#026E41',
      warning: '#A34007',
      disabled: '#6D7175',
    },
    text: {
      default: '#202223',
      subdued: '#6D7175',
      disabled: '#8C9196',
      primary: '#F06543',
      secondary: '#2B2A63',
      tertiary: '#A4A3C1',
      critical: '#E2624A',
      warning: '#D79F35',
      success: '#41B799',
      highlight: '#6E81E8',
      onInteractive: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onCritical: '#FFFFFF',
    },
    icon: {
      default: '#2B2A63',
      subdued: '#525189',
      hovered: '#8D8CC9',
      pressed: '#17164A',
      disabled: '#BFBFD0',
      critical: '#C04830',
      warning: '#D79F35',
      success: '#41B799',
      highlight: '#6E81E8',
      onInteractive: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onCritical: '#FFFFFF',
    },
    interactive: {
      default: '#2C6ECB',
      hovered: '#1F5199',
      depressed: '#103262',
      disabled: '#BDC1CC',
      critical: '#E2624A',
      criticalHovered: '#E88D7C',
      criticalDepressed: '#CE543D',
      criticalDisabled: '#FFB9B5',
    },
    border: {
      default: '#8C9196',
      subdued: '#C9CCCF',
      hovered: '#999EA4',
      depressed: '#575959',
      input: '#E2E8F0',
      disabled: '#D2D5D8',
      shadowSubdued: '#BABFC4',
      criticalDefault: '#E2624A',
      criticalSubdued: '#F0A99C',
      criticalDisabled: '#FFB9B5',
      successDefault: '#41B799',
      successDepressed: '#EAFFF2',
      successSubdued: '#9EE4D3',
      highlightDefault: '#6E81E8',
      highlightSubdued: '#B3BDF3',
    },
    focusedDefault: '#458FFF',
    action: {
      primaryDefault: '#F06543',
      primaryHovered: '#F38468',
      primaryPressed: '#F6A38E',
      primaryDepressed: '#FFAC99',
      primaryDisabled: '#FBD1C7',
      criticalDefault: '#E2624A',
      criticalHovered: '#E57561',
      criticalPressed: '#E57561',
      criticalDepressed: '#F1B2A7',
      criticalDisabled: '#F6D0C9',
      secondaryDefault: '#2B2A63',
      secondaryHovered: '#323172',
      secondaryPressed: '#3F3D8F',
      secondaryDepressed: '#4B49AB',
      secondaryDisabled: '#BFBFD0',
      highlightSofblue: '#BCC5F4',
    },
  },
  fontFamily: {
    barlow: 'Barlow, sans-serif',
    roboto: 'Roboto, sans-serif',
  },
  fontSize: {
    h1: '4.2rem',
    h2: '3.6rem',
    h3: '3.2rem',
    sh1: '2.8rem',
    sh2: '2.4rem',
    sh3: '2.2rem',
    copy1: '2rem',
    copy2: '1.8rem',
    copy3: '1.6rem',
    copy4: '1.4rem',
    copy5: '1.2rem',
    small: '1rem',
  },
  fontWeight: {
    black: 900,
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
    light: 300,
  },
  lineHeight: {
    h1: '5rem',
    h2: '4.6rem',
    h3: '3.8rem',
    sh1: '3.2rem',
    sh2: '2.8rem',
    sh3: '2.6rem',
    copy1: '2.3rem',
    copy2: '2.1rem',
    copy3: '1.9rem',
    copy4: '1.6rem',
    copy5: '1.4rem',
    small: '1.2rem',
  },
  breakpoints: {
    desktopLarge: 1440,
    desktop: 1279,
    desktopSmall: 1040,
    tablet: 864,
    maxMobile: 412,
  },
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    ::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        margin-left: 1px;
      }
  
      ::-webkit-scrollbar-track {
        border-radius: 6px;
        margin: 5px;
      }
  
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.icon.disabled};
        border-radius: 6px;
      }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      ::-webkit-scrollbar {
        width:8px;
        margin-left: 1px;
      }
  
      ::-webkit-scrollbar-track {
        border-radius: 6px;
        margin: 5px;
      }
  
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.icon.hovered};
        border-radius: 6px;
      }
    }
  }
  
  html {
    line-height: 1.15; 
    -webkit-text-size-adjust: 100%;
    font-size: 9.85px;

    @media (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 9.90px;
    }
    @media (min-width: ${theme.breakpoints.desktopSmall}px) {
        font-size: 9.92px;
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 9.95px;
    }
    @media (min-width: ${theme.breakpoints.desktopLarge}px) {
        font-size: 10px;
    }
  }
  body {
    margin: 0;
    background-color: ${theme.colors.background.default};
  }
  main {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15; 
    margin: 0; 
  }
  button,
  input { 
    overflow: visible;
  }
  button,
  select { 
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button; 
    font: inherit; 
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
`
