// Models
import { Props as BaseProps, TPublicProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Components
import BaseBlock from '../BaseBlock'
import { Aligner, InputText, TextArea } from 'heeds-ds'

type Props = Pick<BaseProps, TPublicProps>

const SectionBlock: FC<Props> = ({ name, ...props }) => {
  return (
    <BaseBlock {...props} borderTop name={name} type="section">
      <Aligner flex="column" gap="2.4rem">
        <InputText
          label="Digite o nome da seção"
          name={`${name}.name`}
          placeholder="Digite o nome da seção aqui"
          scale="small"
          mandatory
        />

        <TextArea
          displayError={false}
          label="Descrição"
          name={`${name}.description`}
          placeholder="Digite uma descrição se for necessário"
          scale="small"
        />
      </Aligner>
    </BaseBlock>
  )
}

export default SectionBlock
