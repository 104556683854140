// React
import { FC, useLayoutEffect } from 'react'

// Libraries
import { useDispatch } from 'react-redux'

// Misc
import { triggerClearWorkoutModels } from 'storage/workoutModel/duck'

// Components
import { Aligner, Loading } from 'heeds-ds'

const LoadingWebview: FC = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(triggerClearWorkoutModels())
  }, [dispatch])

  useLayoutEffect(() => {
    if (window.ReactNativeWebView?.injectedObjectJson()) {
      const { keyValue, value } = JSON.parse(
        window.ReactNativeWebView.injectedObjectJson(),
      )

      if (keyValue && value) {
        window.postMessage = function (data) {
          window.ReactNativeWebView.postMessage(data)
        }
        localStorage.setItem('webview', 'true')
        localStorage.setItem(keyValue, JSON.stringify(value))
        window.dispatchEvent(new StorageEvent('storage', { key: keyValue }))
      }
    }
  }, [])

  return (
    <Aligner>
      {process.env.NODE_ENV === 'development' && (
        <p>
          {window.ReactNativeWebView
            ? window.ReactNativeWebView.injectedObjectJson()
            : 'NO INJECTED OBJECT'}
        </p>
      )}
      <Loading active />
    </Aligner>
  )
}

export default LoadingWebview
