// Models
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'
import {
  ILoadAnamneseTemplatesSendResponse,
  ISendAnamneseTemplatesPayload,
  ICreateAnamneseTemplatePayload,
  ILoadAnamneseTemplatePayload,
  ILoadAnamneseTemplateResponse,
  IPartialUpdateAnamneseTemplatePayload,
} from './@types'

// Misc
import { getApiErrors } from 'utils/functions'
import { objectToQueryString } from 'lib/query-string'
import api from 'lib/axios/api'
import axios from 'axios'

export const createAnamneseTemplate = async (
  payload: ICreateAnamneseTemplatePayload,
): Promise<IAnamneseTemplate> => {
  try {
    const { data }: { data: IAnamneseTemplate } = await api.post(
      `assessments/anamnesis-template/`,
      payload,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getAnamneseTemplate = async (
  id: string,
): Promise<IAnamneseTemplate> => {
  try {
    const { data }: { data: IAnamneseTemplate } = await api.get(
      `/assessments/anamnesis-template/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateAnamneseTemplate = async (
  payload: IAnamneseTemplate,
): Promise<IAnamneseTemplate> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IAnamneseTemplate } = await api.put(
      `/assessments/anamnesis-template/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partilaUpdateAnamneseTemplate = async (
  payload: IPartialUpdateAnamneseTemplatePayload,
): Promise<IAnamneseTemplate> => {
  try {
    const { id, ...updateData } = payload
    const { data }: { data: IAnamneseTemplate } = await api.patch(
      `/assessments/anamnesis-template/${id}/`,
      updateData,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const deleteAnamneseTemplate = async (
  id: string,
): Promise<IAnamneseTemplate> => {
  try {
    const { data }: { data: IAnamneseTemplate } = await api.delete(
      `/assessments/anamnesis-template/${id}/`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const getAnamneseTemplates = async (
  payload: ILoadAnamneseTemplatePayload,
): Promise<ILoadAnamneseTemplateResponse> => {
  try {
    const { data }: { data: ILoadAnamneseTemplateResponse } = await api.get(
      `assessments/anamnesis-template/`,
      {
        params: payload,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadAnamneseTemplatesSent = async (
  id: string,
): Promise<ILoadAnamneseTemplatesSendResponse> => {
  try {
    const { data }: { data: ILoadAnamneseTemplatesSendResponse } =
      await api.get(`assessments/anamnesis-template/${id}/send-list/`)

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const sendAnamneseTemplate = async (
  payload: ISendAnamneseTemplatesPayload,
): Promise<IAnamneseTemplate> => {
  try {
    const { athlete_ids, id } = payload
    const { data }: { data: IAnamneseTemplate } = await api.post(
      `assessments/anamnesis-template/${id}/send-list/`,
      { athlete_ids },
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
