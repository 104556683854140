// Libraries
import styled from 'styled-components'

// Components
import { Button } from 'heeds-ds'

export const ButtonContainer = styled.form`
  border-top: 1px solid ${({ theme }) => theme.colors.border.input};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 4rem;
  position: sticky;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background: ${({ theme }) => theme.colors.surface.default};
  }
`

export const CancelButton = styled(Button).attrs(() => ({
  cancel: true,
  margin: '0',
  size: 'small',
  variation: 'outlined',
}))``

export const NextButton = styled(Button).attrs(() => ({
  margin: '0',
  size: 'small',
}))``
