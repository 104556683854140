// Models
import { IBaseState } from 'lib/redux/models'
import { NavigateFunction } from 'react-router-dom'
import { TypeUser } from 'models'
import { IGetUserInviteResponse } from 'services/auth/@types'

export enum EAuthActionTypes {
  CLEANUP = 'AUTH/CLEANUP',
  FAILURE = 'AUTH/FAILURE',
  FULFILL = 'AUTH/FULFILL',
  REQUEST = 'AUTH/REQUEST',
  SUCCESS = 'AUTH/SUCCESS',
  NEW_PASSWORD = 'AUTH/NEW_PASSWORD',
  LOGOUT = 'AUTH/LOGOUT',
  REGISTER_EMAIL_CONFIRMATION = 'AUTH/REGISTER_EMAIL_CONFIRMATION',
  REQUEST_FORGOT_PASSWORD = 'AUTH/REQUEST_FORGOT_PASSWORD',
  RESET_PASSWORD = 'AUTH/RESET_PASSWORD',
  RESEND_CONFIRMATION_EMAIL = 'AUTH/RESEND_CONFIRMATION_EMAIL',
  RESEND_INVITE_EMAIL = 'AUTH/RESEND_INVITE_EMAIL',
  TRIGGER_LOGIN = 'AUTH/TRIGGER_LOGIN',
  TRIGGER_REGISTER = 'AUTH/TRIGGER_REGISTER',
  TRIGGER_USER_INVITE = 'AUTH/TRIGGER_USER_INVITE',
  GET_USER_INVITE_LIST = 'AUTH/GET_USER_INVITE_LIST',
  VALIDATE_RESET_TOKEN = 'AUTH/VALIDATE_RESET_TOKEN',
  VALIDATE_FIREBASE_TOKEN = 'AUTH/VALIDATE_FIREBASE_TOKEN',
}

export enum EFirebaseProviderOptions {
  APPLE = 'apple.com',
  EMAIL = 'password',
  FACEBOOK = 'facebook.com',
  GOOGLE = 'google.com',
}

export type TokenType = string

export interface IAuthState extends IBaseState {
  userData?: IUser
  isResetTokenValid?: boolean
  resetPasswordSuccess?: boolean
  newPasswordSuccess?: boolean
  registerConfirmed?: boolean
  success?: boolean
  alert?: boolean
  invite_url?: string
  invite_list?: IGetUserInviteResponse
}

export interface IRegisterPayload {
  email: string
  name?: string
  firebase_uid?: string
  redirection: { navigate: NavigateFunction; to: string }
  password?: string
  provider: string
  type: TypeUser
  has_plans: boolean
  has_appointment_time: boolean
  invite_token?: string
}

export interface IRegisterResponse extends IRegisterPayload {
  user_pk: number
  profile_pk: number
  registered_with_email_from_invite?: boolean
}

export interface ILoginPayload {
  email?: string
  password?: string
  provider: EFirebaseProviderOptions
  type: TypeUser
  redirection: { navigate: NavigateFunction; to: string }
  invite_token?: string
}

export interface IBackendLoginPayload {
  provider: EFirebaseProviderOptions
  token: TokenType
  type: TypeUser
  invite_token?: string
}

export interface ITokensResponse {
  access: TokenType
  refresh: TokenType
}

export interface IEmailInvitePayload {
  email?: string
  athlete_profile_id?: number
}

export interface ILoginDataResponse {
  email: string
  name: string
  pk: number
  profile_pk: number
  provider?: EFirebaseProviderOptions
  type: TypeUser
  has_plans: boolean
  has_completed_profile_data: boolean
  has_appointment_time: boolean
}

export interface ILoginResponse {
  access: string
  refresh: string
  data: ILoginDataResponse
}

export interface INewPasswordPayload {
  newPassword: string
  userId?: number
}

export interface INewPasswordResponse {
  newPassword: string
}

export interface IForgotPasswordPayload {
  email: string
  toast_message?: string
  redirection?: { navigate: NavigateFunction; to: string }
}

export interface IResetPasswordPayload {
  password: string
  redirection?: { navigate: NavigateFunction; to: string }
  token: string
}

export interface IStatusResponse {
  status: 'OK' | string
}

export interface IUser {
  email: string
  name?: string
  profileId?: number
  provider?: EFirebaseProviderOptions
  type?: TypeUser
  userId?: number
  has_plans?: boolean
  has_completed_profile_data?: boolean
  has_appointment_time?: boolean
  registered_with_email_from_invite?: boolean
}

export interface IUserInvitePayload {
  name?: string
  email?: string
  anamnesis_template_id?: string
}

export interface IUserInvite {
  invite_url?: string
}
