// Models
import { ThemeContext } from 'styled-components'

// React
import { FC, useContext } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMenu } from 'hooks/useMenu'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import { LogoMin } from 'components/UI/Image'
import Navbar from 'components/Navbar'

// Assets
import caduLogoMiniBeta from 'assets/images/logo-cadu-mini-beta.svg'

const Menu: FC = () => {
  const { closeMenu, visible } = useMenu()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  if (isDesktop) {
    return (
      <div
        className={cn(
          'sticky bottom-0 left-0 top-0 z-[2] grid w-[92px] grid-rows-[min-content_1fr]',
          'justify-center gap-6 px-2 py-8',
        )}
      >
        <LogoMin src={caduLogoMiniBeta} />
        <Navbar />
      </div>
    )
  }

  return (
    <>
      <div
        onClick={closeMenu}
        className={cn(
          'invisible fixed left-0 top-0 z-[18] h-screen w-full bg-badge-purpleText transition-all',
          'opacity-0 duration-300 ease-in-out xl:hidden',
          { 'opacity-50 visible': visible },
        )}
      />
      <div
        className={cn(
          'invisible fixed bottom-0 left-0 z-[19] max-h-[80vh] w-screen translate-y-full overflow-y-auto',
          'rounded-t-6 bg-surface px-4 pb-4 pt-14 shadow-[2px_2px_13px_1px_rgba(205,205,205,0.25)]',
          'transition-all duration-200 ease-in-out',
          { 'translate-y-0 visible': visible },
        )}
      >
        <div
          onClick={closeMenu}
          onDragEnd={closeMenu}
          className="absolute left-0 top-0 flex h-[37px] w-full items-center justify-center xl:hidden"
        >
          <div className="h-[5px] w-8 select-none rounded-[3px] bg-icon" />
        </div>

        <Navbar />
      </div>
    </>
  )
}

export default Menu
