// React
import { useContext, useEffect, useState } from 'react'

// Libraries
import * as SelectPrimitive from '@radix-ui/react-select'
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'

// Misc
import { cn } from '../../../utils/classes'
import { triggerStyles } from './styles'
import { useMediaQuery } from '../../../hooks'

// Components
import { Icon, InputWrapper } from '../..'

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

type Option = {
  label: string
  value: string
}

type SelectAttrs = React.SelectHTMLAttributes<HTMLSelectElement>

type Props = SelectAttrs & {
  className?: string
  displayError?: boolean
  label?: string
  mandatory?: boolean
  name: string
  options: Option[]
  scale?: 'small' | 'medium' | 'large'
}

const Select = ({
  className,
  disabled = false,
  displayError = true,
  label = '',
  mandatory,
  name,
  options = [],
  placeholder = 'Selecione uma opção:',
  scale = 'medium',
}: Props) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [fieldState, setFieldState] = useState(EFieldStates.default)

  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({ control, name })

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [error, isDirty])

  return (
    <InputWrapper
      className={className}
      color={fieldState}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      label={label}
      mandatory={mandatory}
      size={scale}
    >
      <SelectPrimitive.Root value={value} onValueChange={onChange}>
        <SelectPrimitive.Trigger
          className={cn(triggerStyles({ color: fieldState, scale }))}
        >
          <SelectPrimitive.Value aria-label={value} placeholder={placeholder} />
          <SelectPrimitive.Icon
            className={cn(
              'flex rotate-0 items-center justify-center group-data-[state=open]:rotate-180',
              'text-icon transition-all duration-100 ease-in-out',
            )}
          >
            <Icon
              iconName="expandMore"
              size={EIconSizes.small}
              className="fill-icon"
            />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Content
          className={cn(
            'select-content-animation select-content-scrollbar z-50 min-w-20 overflow-hidden',
            'rounded-1 bg-surface shadow-lg',
          )}
          position="popper"
          ref={(ref) => {
            if (ref) {
              ref.addEventListener('touchend', (e) => e.preventDefault())
            }
          }}
          side={!isDesktop ? 'top' : 'bottom'}
          sideOffset={2}
        >
          <SelectPrimitive.Viewport
            className={cn(
              'max-h-[40vh] w-full overflow-y-auto rounded-1 bg-surface p-[2px]',
              'select-list-size shadow-md',
            )}
          >
            {options.map((option) => {
              return (
                <SelectPrimitive.Item
                  key={option.value}
                  value={option.value}
                  textValue={option.label}
                  className={cn(
                    'flex cursor-pointer items-center gap-1 px-4 py-3 text-copy4 text-text hover:font-bold',
                    'hover:bg-surface-highlightSubduedHovered data-[highlighted]:bg-surface-highlightSubduedHovered',
                    'aria-checked:bg-surface-highlightSubduedHovered data-[highlighted]:outline-none',
                    'aria-checked:font-bold',
                  )}
                >
                  <SelectPrimitive.ItemText>
                    {option.label}
                  </SelectPrimitive.ItemText>
                </SelectPrimitive.Item>
              )
            })}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    </InputWrapper>
  )
}

export default Select
