// Models
import IStore from 'lib/redux/models'

// React
import { FC, useMemo } from 'react'

// Libraries
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { theme } from 'lib/styled-components/theme'
import {
  triggerActivateSubscription,
  triggerDeactivateSubscription,
} from 'storage/financialSubscription/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerLoadInstallments } from 'storage/financial/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { useModal } from 'hooks'

// Custom
import * as Styled from './styled'
import { Avatar, Button, IconButton, IconsType } from 'heeds-ds'
import { Body, Subtitle } from 'components/UI/Typography'
import { Modal } from 'components'

type Props = {
  name?: string
  photo?: string
  icon?: IconsType
  subTitle?: string
}

// Constants
const STATUS_PROPS = {
  active: {
    button: 'Desativar Aluno',
    modal: {
      description:
        'O status do aluno ficará como desativado para o sistema. Você poderá reativar esse aluno a qualquer momento. Deseja prosseguir?',
      title: 'Desativar aluno',
      confirmTitle: 'Desativar',
    },
    toast: 'desativado',
  },
  inactive: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja reativar este aluno?`,
      title: 'Reativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
  invited: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja ativar este aluno?`,
      title: 'Ativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
}

const Profile: FC<Props> = ({ name, photo, icon, subTitle }) => {
  const { id = '' } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { closeModal, openModal, isVisible } = useModal()
  const { overview } = useSelector((state: IStore) => state.athleteOverview)
  const { subscription } = useSelector(
    (state: IStore) => state.financialSubscription,
  )

  const status = useMemo(() => overview?.status ?? 'inactive', [overview])

  const displayDeactivateButton = ['financeiro', 'dados-gerais'].some((item) =>
    location.pathname.split('/').includes(item),
  )

  const handleChangeStatus = () => {
    const successCallback = () => {
      dispatch(triggerLoadAthleteOverview(Number(id)))
      dispatch(triggerLoadInstallments({ athlete_pk: Number(id) }))
      dispatch(
        triggerToastSuccess({
          customTitle: 'Sucesso',
          message: `Aluno ${overview?.name ?? ''} foi ${
            STATUS_PROPS[status].toast
          }.`,
        }),
      )
    }
    if (subscription) {
      const payload = {
        id: subscription.id,
        successCallback,
      }

      dispatch(
        status === 'active'
          ? triggerDeactivateSubscription(payload)
          : triggerActivateSubscription(payload),
      )
    }

    closeModal()
  }

  return (
    <Styled.Container data-testid="profile">
      <Styled.ProfileBackground>
        <Avatar
          className="mr-[23px]"
          name={name ?? ''}
          scale="small"
          src={photo}
        />
        <Styled.TextWrapper>
          <Subtitle type="sh3" weight={500}>
            {name ?? 'Perfil do aluno'}
          </Subtitle>
          <Body type="copy1" weight={500}>
            {name ?? 'Perfil do aluno'}
          </Body>
          <Body type="copy4" weight={400} color={theme.colors.text.subdued}>
            {subTitle}
          </Body>
        </Styled.TextWrapper>
        {displayDeactivateButton && (
          <div className="ml-auto hidden xl:flex">
            <Button
              cancel={status === 'active'}
              onClick={() => openModal('(de)activate-athlete-modal')}
              size="xsmall"
              track={buttonClickTracking}
              trackName="open_athlete_activate_or_deactivate_modal"
            >
              {STATUS_PROPS[status].button}
            </Button>
          </div>
        )}
        {icon && (
          <IconButton
            iconName={icon}
            size="small"
            track={buttonClickTracking}
            trackName={`custom_profile_action_${icon}`}
            variation="main"
          />
        )}
      </Styled.ProfileBackground>
      {isVisible === '(de)activate-athlete-modal' && (
        <Modal
          {...STATUS_PROPS[status].modal}
          icon="warning"
          maxWidth="530px"
          primaryButton={{
            name: `Sim, quero ${status === 'active' ? 'desativar' : 'ativar'}`,
            onClick: handleChangeStatus,
          }}
          secondaryButton={{ name: 'Cancelar' }}
        />
      )}
    </Styled.Container>
  )
}

export default Profile
