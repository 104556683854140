// Models
import IStore from 'lib/redux/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { IPlan } from 'views/dashboard/athlete/AthleteFinancial'
import { useFormContext } from 'react-hook-form'

// React
import { FC, useCallback, useContext, useEffect, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery } from 'hooks'
import { triggerGetProfilePlan } from 'storage/financial/duck'

// Components
import { Aligner, Body, IconButton, Switch, Tag, ToggleGroup } from 'heeds-ds'
import RadioTag, { labeledOptions } from 'components/RadioTag'

export enum EPlanModelsPortuguese {
  monthly = 'Mensal',
  quarterly = 'Trimestral',
  semiannual = 'Semestral',
  yearly = 'Anual',
}

export type TCustomOptions = {
  id: number
  label: string
  prices: labeledOptions[]
  showFrequency: boolean
  value: string
}

enum EPlanModelMultiply {
  monthly = 1,
  quarterly = 3,
  semiannual = 6,
  yearly = 12,
}

const Plans: FC = () => {
  const { planList } = useSelector<IStore, IFinancialState>(
    ({ financial }) => financial,
  )
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { personal_id = '' } = useOutletContext<IAthleteOutletContext>()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const { watch } = useFormContext()

  const plan = watch('selected_plan')
  const planModelForm = watch('plan_model')
  const planTypeForm = watch('plan_type')

  const handlePlanModels = () => {
    const inPerson = planList?.results?.some((model) => {
      return model?.plan_model === 'in_person'
    })
      ? { label: 'PRESENCIAL', value: 'in_person' }
      : { label: '', value: '' }

    const online = planList?.results?.some((model) => {
      return model?.plan_model === 'online'
    })
      ? { label: 'CONSULTORIA ONLINE', value: 'online' }
      : { label: '', value: '' }

    const models = [online, inPerson].filter((item) => item.label !== '')

    return models
  }

  const handlePlanTypes = () => {
    const inPerson = planList?.results?.some((model) => {
      return model.plan_type === 'hourly_rate'
    })
      ? { label: 'HORA/AULA', value: 'hourly_rate' }
      : { label: '', value: '' }

    const online = planList?.results?.some((model) => {
      return model.plan_type === 'custom'
    })
      ? { label: 'PLANOS PERSONALIZADOS', value: 'custom' }
      : { label: '', value: '' }

    const models = [inPerson, online].filter((item) => item.label !== '')

    return models
  }

  const handleOnlineOptions = () => {
    return planList?.results?.reduce((accumulator: IPlan[], onlinePlan) => {
      if (onlinePlan?.plan_model === 'online') {
        const plan: IPlan = {
          label:
            EPlanModelsPortuguese[
              onlinePlan.plan_periodicity as keyof typeof EPlanModelsPortuguese
            ],
          value: `${onlinePlan?.plan_type}_${onlinePlan.plan_periodicity}`,
          price: onlinePlan.value.standard,
          planID: onlinePlan.id,
          frequency: EPlanModelMultiply[onlinePlan.plan_periodicity].toString(),
          type: onlinePlan?.plan_type,
          model: onlinePlan?.plan_model,
          periodicity: onlinePlan.plan_periodicity,
        }

        accumulator.push(plan)
      }
      return accumulator
    }, [])
  }

  const handleCustomOptions = useCallback(() => {
    return planList?.results?.reduce(
      (accumulator: TCustomOptions[], onlinePlan) => {
        if (onlinePlan.plan_type === 'custom') {
          const portuguesePlan =
            EPlanModelsPortuguese[
              onlinePlan.plan_periodicity as keyof typeof EPlanModelsPortuguese
            ]

          const prices = Object.values(onlinePlan?.value?.custom || [])
            .map((value, index) => {
              return value !== 0
                ? {
                    label: `${index + 1} ${
                      index + 1 === 1 ? 'vez' : 'vezes'
                    }/semana`,
                    value: `${onlinePlan?.plan_type}_${
                      onlinePlan.plan_periodicity
                    }${index + 1}`,
                    price: value,
                    planID: onlinePlan.id,
                    frequency: (index + 1).toString(),
                    type: onlinePlan?.plan_type,
                    model: onlinePlan?.plan_model,
                    periodicity: onlinePlan.plan_periodicity,
                  }
                : {
                    label: '',
                  }
            })
            .filter((item) => item.label !== '')

          accumulator.push({
            id: onlinePlan.id || 0,
            label: portuguesePlan,
            value: onlinePlan.plan_periodicity,
            showFrequency:
              plan?.type === 'custom' &&
              plan?.periodicity === onlinePlan.plan_periodicity,
            prices,
          })
        }

        return accumulator
      },
      [],
    )
  }, [plan?.periodicity, plan?.type, planList?.results])

  const [plansValues, setPlansValues] = useState(handleCustomOptions())

  const handleHourlyRate = () => {
    return planList?.results?.reduce(
      (accumulator: labeledOptions[], hourlyRate) => {
        if (hourlyRate.plan_type === 'hourly_rate') {
          accumulator.push({
            label: 'Hora/aula',
            value: hourlyRate.plan_type,
            price: hourlyRate.value.standard,
            planID: hourlyRate.id,
            type: hourlyRate?.plan_type,
            model: hourlyRate?.plan_model,
            periodicity: hourlyRate.plan_periodicity,
          })
        }
        return accumulator
      },
      [],
    )
  }

  const handleShowFrequency = (id: number) => {
    const updatedArray = plansValues?.map((item) => {
      if (item.id === id) {
        return { ...item, showFrequency: !item.showFrequency }
      }
      return item
    })

    setPlansValues(updatedArray)
  }

  useEffect(() => {
    if (planTypeForm === 'custom') setPlansValues(handleCustomOptions())
  }, [handleCustomOptions, planTypeForm])

  useEffect(() => {
    const profileId =
      userData?.profileId || (personal_id && Number(personal_id))
    if (profileId) {
      dispatch(
        triggerGetProfilePlan({
          profile_pk: profileId,
        }),
      )
    } else {
      window.postMessage('NO_PROFILE_ID')
      window.postMessage('CLOSE_WEBVIEW')
    }
  }, [dispatch, userData, personal_id])

  return (
    <Aligner flex="column" width={isDesktop ? '570px' : '100%'}>
      <Body
        type="copy1"
        color={theme.colors.text.secondary}
        weight={600}
        margin="0"
      >
        Modelo do plano
      </Body>
      <ToggleGroup
        optionSize={isDesktop ? 'w-[159px]' : 'w-full'}
        alignment={isDesktop ? 'row' : 'column'}
        name={'plan_model'}
        options={handlePlanModels()}
        scale={isDesktop ? 'medium' : 'large'}
        className="mb-5"
      />
      {planModelForm === 'in_person' ? (
        <Aligner flex="column">
          <Body
            type="copy1"
            color={theme.colors.text.secondary}
            weight={600}
            margin="0"
          >
            Tipos de plano
          </Body>
          <ToggleGroup
            optionSize={isDesktop ? 'w-auto' : 'w-full'}
            alignment={isDesktop ? 'row' : 'column'}
            name={'plan_type'}
            options={handlePlanTypes()}
            scale={isDesktop ? 'medium' : 'large'}
          />
          {planTypeForm === 'hourly_rate' ? (
            <RadioTag
              name={'selected_plan'}
              options={handleHourlyRate()}
              column
            />
          ) : null}
          {planTypeForm === 'custom'
            ? plansValues?.map((planValue, index) => {
                const { label, showFrequency } = planValue
                return (
                  <Aligner key={index} width="100%">
                    <Tag className="mb-4 xl:mb-5">
                      <Aligner flex="column">
                        <Aligner
                          align="center"
                          margin={planValue.showFrequency ? '0 0 26px 0' : '0'}
                        >
                          <Aligner flex="column">
                            <Body type="copy3" weight={600}>
                              Plano {label}
                            </Body>
                          </Aligner>
                          <Aligner justify="flex-end">
                            <IconButton
                              iconName={`expand${
                                showFrequency ? 'Less' : 'More'
                              }`}
                              onClick={() => handleShowFrequency(planValue.id)}
                              size="small"
                              track={buttonClickTracking}
                              trackName="expand_custom_plan"
                            />
                          </Aligner>
                        </Aligner>
                        {planValue.showFrequency && (
                          <RadioTag
                            tagLess
                            name={'selected_plan'}
                            options={planValue.prices.map(
                              (value: labeledOptions) => {
                                return {
                                  ...value,
                                }
                              },
                            )}
                            column
                          />
                        )}
                      </Aligner>
                    </Tag>
                  </Aligner>
                )
              })
            : null}
        </Aligner>
      ) : planModelForm === 'online' ? (
        <Aligner flex="column" width={isDesktop ? '570px' : '100%'}>
          <RadioTag
            name="selected_plan"
            options={handleOnlineOptions()}
            column
          />
        </Aligner>
      ) : null}
      <Tag className="mt-6">
        <Aligner align="center">
          <Switch name="autoRenovation" />
          <Body type="copy3" weight={600} margin="0 0 0 16px">
            Renovação automática
          </Body>
        </Aligner>
      </Tag>
    </Aligner>
  )
}

export default Plans
