// Models
import { getApiErrors } from 'utils/functions'

// Misc
import { IAthleteOverview } from './@types'
import api from 'lib/axios/api'
import axios from 'axios'

export const getAthleteOverview = async (
  id: number,
): Promise<IAthleteOverview> => {
  try {
    const { data }: { data: IAthleteOverview } = await api.get(
      `athletes/${id}/overview`,
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
