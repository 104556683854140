// Models
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import { TExercisesGroupFilter } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Components
import * as Styled from './styled'
import { CommonList } from 'heeds-ds'
import { LoaderExerciseItem, Modal } from 'components'
import ExerciseSearchBar from 'blocks/dashboard/workout/ExerciseSearchBar'

type Props = {
  filters?: TExercisesGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  onClickFilter: () => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFilter: (filter: TExercisesGroupFilter) => void
  renderExercise: (exercise: IExerciseData) => JSX.Element
}

const ModalExercises: FC<Props> = ({
  filters,
  inputValue,
  loadMoreExercises,
  onClickFilter,
  onSearchChange,
  removeFilter,
  renderExercise,
}) => {
  const { exercises, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )

  return (
    <Modal>
      <Styled.ModalContainer data-testid="modal-exercices-container">
        <ExerciseSearchBar
          filters={filters}
          onClickFilter={onClickFilter}
          onSearchChange={onSearchChange}
          removeFilter={removeFilter}
          searchQuery={inputValue}
        />

        {loading ? (
          <div className="no-scrollbar flex flex-col gap-2 overflow-auto">
            {Array(7)
              .fill(1)
              .map((_, index) => (
                <LoaderExerciseItem key={index} />
              ))}
          </div>
        ) : (
          <CommonList<IExerciseData>
            columns={[]}
            data={exercises?.results || []}
            hasMore={!!exercises?.next}
            onEndReached={loadMoreExercises}
            renderItem={renderExercise}
            loaderComponent={<LoaderExerciseItem />}
            showHeader={false}
            listEmptyComponent={
              <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-2">
                <p className="text-center text-copy2 font-semibold text-text-subdued">
                  Não encontramos nenhum exercício correspondente à sua busca.
                </p>
                <p className="text-center text-copy2 text-text-subdued">
                  Experimente procurar outro nome. Você também pode utilizar os
                  filtros para refinar sua pesquisa.
                </p>
              </div>
            }
          />
        )}
      </Styled.ModalContainer>
    </Modal>
  )
}

export default ModalExercises
