// Models
import { IAthleteInfo, IUpdateAthlete, TCreateAthletePayload } from './@types'

//
import { getApiErrors } from 'utils/functions'
import api from 'lib/axios/api'
import axios from 'axios'

export const createAthlete = async (
  payload: TCreateAthletePayload,
): Promise<IAthleteInfo> => {
  try {
    const { data }: { data: IAthleteInfo } = await api.post(
      `athletes/`,
      payload,
    )
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadAthleteInformation = async (
  id: number,
): Promise<IUpdateAthlete> => {
  try {
    const { data }: { data: IAthleteInfo } = await api.get(`athletes/${id}/`)
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateAthleteInformation = async (
  fullPayload: IUpdateAthlete,
): Promise<IUpdateAthlete> => {
  try {
    const { id, ...payload } = fullPayload
    const { data }: { data: IAthleteInfo } = await api.patch(
      `athletes/${id}/`,
      payload,
    )
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error) {
      throw new Error(getApiErrors(error))
    }
    const err = error as Error
    throw new Error(err.message)
  }
}
